import { useEffect, useRef, useState } from "react";
import { CommonConstant } from "../../core/constant/common.constant";
import AppSelect from "../../components/common/AppSelect";
import InventionRegisPreview from "../../components/InventionRegisPreview";
import InventionService from "../../core/services/InventionService";
import TccoFileService from "../../core/services/common/TccoFileService";
import { SaveInventionRequest } from "../../core/model/request/SaveInventionRequest";
import { useAppSelector } from "../../libs/hooks";
import { useNavigate } from "react-router-dom";

export default function PatentRegistration() {
  const fileAttachRef = useRef<any>(null);
  const fileTechnicalRef = useRef<any>(null);

  const [relatedFile, setRelatedFile] = useState<any>([]);
  const [technicalFile, setTechnicalFile] = useState<any>([]);
  const [fileRelatedUploaded, setFileRelatedUploaded] = useState<any>([]);
  const [fileTechnicalUploaded, setFileTechnicalUploaded] = useState<any>([]);
  const [isFileRelatedUploaded, setIsFileRelatedUploaded] = useState<any>(false);
  const [isFileTechnicalUploaded, setIsFileTechnicalUploaded] = useState<any>(false);

  const [model, setModel] = useState<any>({});
  const [showPreview, setShowPreview] = useState<any>(false);
  const previewPdfRef = useRef<any>(null);

  const navigate = useNavigate();

  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const isLogin = useAppSelector((state) => state.userInfo.isLogin);

  useEffect(() => {
    if (showPreview) {
      scrollToView();
    }
  }, [showPreview]);

  const scrollToView = () => {
    previewPdfRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleFileAttachChange = (event: any) => {
    let arrFile: any = [];
    let filesUpload: any = Array.from(event.target.files);
    for (let ele of filesUpload) {
      if (relatedFile) {
        if (relatedFile.filter((file: any) => file.name === ele.name && file.size === ele.size).length === 0) {
          arrFile.push(ele);
        }
      } else {
        arrFile.push(ele);
      }
    }
    setRelatedFile(relatedFile ? relatedFile.concat(arrFile) : arrFile);
  };

  const handleAttachFileRemove = (idx: any) => {
    let fileUpload = relatedFile.filter((file: any, index: any) => index !== idx);
    setRelatedFile(fileUpload);
  };

  const handleFileTechnicalChange = (event: any) => {
    let arrFile: any = [];
    let filesUpload: any = Array.from(event.target.files);
    for (let ele of filesUpload) {
      if (technicalFile) {
        if (technicalFile.filter((file: any) => file.name === ele.name && file.size === ele.size).length === 0) {
          arrFile.push(ele);
        }
      } else {
        arrFile.push(ele);
      }
    }
    setTechnicalFile(technicalFile ? technicalFile.concat(arrFile) : arrFile);
  };

  const handleTechnicalFileRemove = (idx: any) => {
    let fileUpload = technicalFile.filter((file: any, index: any) => index !== idx);
    setTechnicalFile(fileUpload);
  };

  const onChangeApplicantRole = (event: any) => {
    setModel({ ...model, applicantRole: event });
  };

  const onChangeResearchField = (event: any) => {
    setModel({ ...model, researchField: event });
  };

  const handleChangeInput = (event: any) => {
    setModel({ ...model, [event.target.name]: event.target.value });
  };

  const handleOptionChange = (event: any) => {
    setModel({ ...model, inventionRecognized: event.target.value });
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleBack = () => {
    setShowPreview(false);
  };

  useEffect(() => {
    if (isFileRelatedUploaded === true && isFileTechnicalUploaded === true) {
      let req = JSON.parse(JSON.stringify(model));
      req.applicantRole = model.applicantRole ? model.applicantRole.value : "";
      req.inventionField = model.researchField ? model.researchField.value : "";
      req.createdBy = userInfo.userUid;
      req.relatedDocuments = fileRelatedUploaded;
      req.technicalDocuments = fileTechnicalUploaded;
      InventionService.getInstance()
        .saveInvention(new SaveInventionRequest().convertRequest(req))
        .finally(() => {
          setIsFileRelatedUploaded(false);
          setFileTechnicalUploaded(false);
        })
        .then((res: any) => {
          if (res.data.status) {
            navigate("/sang-che-cua-toi");
          }
        })
        .catch((err: any) => {});
    }
  }, [isFileRelatedUploaded, isFileTechnicalUploaded]);

  const handleSave = () => {
    if (!isLogin) {
      let domain = window.location.origin;
      var url = process.env.REACT_APP_AUTH_URL + "/login?redirect_uri=" + domain || "";
      window.location.href = url;
    } else {
      if (relatedFile.length > 0) {
        TccoFileService.getInstance()
          .saveFiles(relatedFile)
          .then((res: any) => {
            if (res.data.status) {
              setFileRelatedUploaded(res.data.responseData.map((item: any) => item.atchFleSeq));
              setIsFileRelatedUploaded(true);
            }
          });
      } else {
        setIsFileRelatedUploaded(true);
      }
      if (technicalFile.length > 0) {
        TccoFileService.getInstance()
          .saveFiles(technicalFile)
          .then((res: any) => {
            if (res.data.status) {
              setFileTechnicalUploaded(res.data.responseData.map((item: any) => item.atchFleSeq));
              setIsFileTechnicalUploaded(true);
            }
          });
      } else {
        setIsFileTechnicalUploaded(true);
      }
    }
  };

  return (
    <>
      <div className="section-spacing pb-1">
        <div className="container pt-3">
          <div className="row g-4">
            <div className="col-12 col-xxl-10 offset-xxl-1">
              <h1 className="d-none d-lg-block display-6 display-lg font-family-poppins fw-semi-bold uppercase">
                <span className="text-gradient-5 fw-bold display-3">Đăng Ký Thông Tin Sáng Chế</span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      {!showPreview && (
        <div className="section-spacing">
          <div className="container">
            <div className="row g-5 mb-3 justify-content-center">
              <div className="col-12">
                <div className="form-border-radius">
                  <div className="mb-3">
                    <span>
                      <b>1. Thông tin cá nhân/tổ chức :</b>
                    </span>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-4">Tên cá nhân/tổ chức:</div>
                    <div className="col-8">
                      <input name="ownerNm" value={model.ownerNm || ""} onChange={handleChangeInput} className="mb-0" type="text" required />
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-4">Địa chỉ:</div>
                    <div className="col-8">
                      <input name="ownerAddr" value={model.ownerAddr || ""} onChange={handleChangeInput} type="text" required />
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-4">Số điện thoại:</div>
                    <div className="col-8">
                      <input name="ownerPhone" value={model.ownerPhone || ""} onChange={handleChangeInput} className="mb-0" type="phone" required />
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-4">Email:</div>
                    <div className="col-8">
                      <input name="ownerEmail" value={model.ownerEmail || ""} onChange={handleChangeInput} className="mb-0" type="email" required />
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-4">Số CMND/CCCD hoặc mã số doanh nghiệp (nếu có):</div>
                    <div className="col-8">
                      <input name="ownerIdentify" value={model.ownerIdentify || ""} onChange={handleChangeInput} className="mb-0" type="text" required />
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-4">Vai trò đối với công trình nghiên cứu:</div>
                    <div className="col-8">
                      <AppSelect
                        onChange={onChangeApplicantRole}
                        value={model.applicantRole}
                        options={CommonConstant.APPLICANT_ROLES}
                        placeHolder="Vai trò trong nghiên cứu"
                        labelName="label"
                        labelValue="value"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-border-radius">
                  <div className="mb-3">
                    <span>
                      <b>2. Thông tin sáng chế:</b>
                    </span>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-4">Tên sáng chế:</div>
                    <div className="col-8">
                      <input name="inventionNm" value={model.inventionNm || ""} onChange={handleChangeInput} className="mb-0" type="text" required />
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-4">Lĩnh vực nghiên cứu:</div>
                    <div className="col-8">
                      <AppSelect
                        onChange={onChangeResearchField}
                        value={model.researchField}
                        options={CommonConstant.RESEARCH_FIELD}
                        placeHolder="Lĩnh vực nghiên cứu"
                        labelName="label"
                        labelValue="value"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-4">Tóm tắt sáng chế:</div>
                    <div className="col-8">
                      <textarea name="inventionSummary" value={model.inventionSummary} onChange={handleChangeInput}></textarea>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-4"></div>
                    <div className="col-8">
                      <div className="mb-4">
                        <input type="radio" className="w-auto me-2" name="inventionRecognized" value="true" checked={model.inventionRecognized === "true"} onChange={handleOptionChange} />
                        <span>
                          Kết quả nghiên cứu khoa học và công nghệ <b>đã đạt được</b> các giải thưởng, công bố, bảo hộ sở hữu trí tuệ trong nước và quốc tế.
                        </span>
                      </div>
                      <div>
                        <input type="radio" className="w-auto me-2" name="inventionRecognized" value="false" checked={model.inventionRecognized === "false"} onChange={handleOptionChange} />
                        <span>
                          Kết quả nghiên cứu khoa học và công nghệ <b>chưa được công nhận</b> qua các giải thưởng, công bố, bảo hộ sở hữu trí tuệ trong nước và quốc tế.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">Các tài liệu liên quan (nếu có):</div>
                    <div className="col-8">
                      <div className="d-flex flex-wrap gap-2 mb-1">
                        {relatedFile?.map((file: any, idx: any) => (
                          <div key={idx} className="p-2" style={{ background: "#f1f2fa" }}>
                            <i className="bi bi-file-text me-2"></i>
                            {file.name}
                            <i className="bi bi-x-lg ms-2 cursor-pointer" onClick={() => handleAttachFileRemove(idx)}></i>
                          </div>
                        ))}
                      </div>
                      <button className="button button-lg button-rounded button-gradient-5 button-hover-slide" type="submit" onClick={() => fileAttachRef.current.click()}>
                        <span data-text="Chọn file">Chọn file</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-border-radius">
                  <div className="mb-3">
                    <span>
                      <b>3. Kết quả nghiên cứu:</b>
                    </span>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">Bản vẽ, thiết kế, quy trình, sơ đồ:</div>
                    <div className="col-8">
                      <div className="d-flex flex-wrap gap-2 mb-1">
                        {technicalFile?.map((file: any, idx: any) => (
                          <div key={idx} className="p-2" style={{ background: "#f1f2fa" }}>
                            <i className="bi bi-file-text me-2"></i>
                            {file.name}
                            <i className="bi bi-x-lg ms-2 cursor-pointer" onClick={() => handleTechnicalFileRemove(idx)}></i>
                          </div>
                        ))}
                      </div>
                      <button className="button button-lg button-rounded button-gradient-5 button-hover-slide" type="submit" onClick={() => fileTechnicalRef.current.click()}>
                        <span data-text="Chọn file">Chọn file</span>
                      </button>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">Kết quả khảo sát, phân tích, đánh giá, thử nghiệm:</div>
                    <div className="col-8">
                      <textarea name="inventionResult" value={model.inventionResult} onChange={handleChangeInput}></textarea>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">Giá trị kinh tế - xã hội, môi trường:</div>
                    <div className="col-8">
                      <textarea name="inventionValue" value={model.inventionValue} onChange={handleChangeInput}></textarea>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">Nội dung, phạm vi, quy mô thực hiện nghiên cứu, ứng dụng chuyển giao:</div>
                    <div className="col-8">
                      <textarea name="transferContent" value={model.transferContent} onChange={handleChangeInput}></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex gap-4 justify-content-center">
              <button className="button button-lg button-rounded button-gradient-5 button-hover-slide" type="submit" onClick={handleSave}>
                <span data-text="Gửi">Gửi</span>
              </button>
              <button className="button-lg button button-rounded button-outline button-hover-slide" type="submit" onClick={handlePreview}>
                <span data-text="Xem trước">Xem trước</span>
              </button>
            </div>
          </div>
          <input type="file" ref={fileAttachRef} multiple style={{ display: "none" }} onChange={handleFileAttachChange} />
          <input type="file" ref={fileTechnicalRef} multiple style={{ display: "none" }} onChange={handleFileTechnicalChange} />
        </div>
      )}

      {showPreview && (
        <>
          <div className="section-spacing">
            <div className="container preview-pdf" ref={previewPdfRef}>
              <InventionRegisPreview content={model} />
              <div className="d-flex gap-4 justify-content-center">
                <button className="button-lg button button-rounded button-outline button-hover-slide" type="submit" onClick={handleBack}>
                  <span data-text="Quay lại">Quay lại</span>
                </button>
                <button className="button button-lg button-rounded button-gradient-5 button-hover-slide" type="submit" onClick={handleSave}>
                  <span data-text="Gửi">Gửi</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
