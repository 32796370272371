import axios from "axios";
import { HeadersUtil } from "../../utils/headersUtil";

export default class TccoFileService {
  private static service: TccoFileService;

  public static getInstance(): TccoFileService {
    if (!TccoFileService.service) {
      TccoFileService.service = new TccoFileService();
    }
    return TccoFileService.service;
  }

  public saveFiles(files: any[]) {
    const formData = new FormData();
    Array.from(files).forEach((file, index) => {
      formData.append("filesData", file);
    });
    const url = process.env.REACT_APP_COMMON_SERVICE_URL + "/common/file-service/saveFiles";
    return axios.post(url, formData, {
      headers: HeadersUtil.getHeadersAuthFormData(),
    });
  }

  public downloadFile(atchFleSeq: any) {
    const url = process.env.REACT_APP_COMMON_SERVICE_URL + "/common/file-service/downloadFile?atchFleSeq=" + atchFleSeq;
    return axios.get(url, {
      responseType: "blob",
      headers: HeadersUtil.getHeadersAuth(),
    });
  }
}
