import { useState } from "react";

interface AutocompleteProps {
  /**
   * Value of input
   */
  value: string;
  /**
   * Placeholder for input
   */
  placeholder: string;
  /**
   * List suggestions
   */
  suggestion: Array<any>;
  /**
   * Option selected
   */
  optionSlt: any;
  /**
   * Class name for input and autocomplete
   */
  className?: string;
  /**
   * Field to display
   */
  bindLabel: string;
  /**
   * Field to display
   */
  loading: boolean;
  /**
   * Event emitted when input is changed
   */
  handleChangeInput: (event: any) => void;
  /**
   * Event emitted when option is selected
   */
  handleSelectOption: (event: any) => void;
  /**
   * Event emitted when option is selected
   */
  handleEnter: (event: any) => void;
}

export default function AppAutocomplete(props: AutocompleteProps) {
  const handleChangeInput = (event: any) => {
    props.handleChangeInput(event);
  };

  const handleSelectOption = (suggestion: any) => {
    props.handleSelectOption(suggestion);
  };

  const handleEnter = (event:any) => {
    props.handleEnter(event);
  };

  return (
    <>
      <div className="autocomplete position-relative">
        <input type="text" value={props.value || ""} className={"mt-4 mb-0" + props.className} onKeyUp={handleEnter} placeholder={props.placeholder} onChange={handleChangeInput}></input>
        {props.suggestion.length > 0 && Object.keys(props.optionSlt).length == 0 && !props.loading && (
          <ul className={"suggestions border-primary rounded-md border-2 p-4 " + props.className}>
            {props.suggestion.map((suggestion: any, index: any) => (
              <li key={index} className="p-3" onClick={() => handleSelectOption(suggestion)}>
                {suggestion[props.bindLabel]}
              </li>
            ))}
          </ul>
        )}
        {props.suggestion.length == 0 && props.value != "" && !props.loading && (
          <ul className={"suggestions border-primary rounded-md border-2 p-4 " + props.className}>
            <li className="py-3">No data available !</li>
          </ul>
        )}
        {props.value != "" && props.loading && (
          <ul className={"suggestions border-primary rounded-md border-2 p-4 " + props.className}>
            <div className="d-flex justify-content-center py-5">
              <div className="loading"></div>
            </div>
          </ul>
        )}
      </div>
    </>
  );
}
