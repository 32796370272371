import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player";
import animationData from "../../../assets/animate/invention.json";
import { Animated } from "react-animated-css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import Marquee from "react-fast-marquee";
import { Link, useNavigate } from "react-router-dom";
import AppAutocomplete from "../../components/common/AppAutoComplete";
import InventionService from "../../core/services/InventionService";
import { CommonConstant } from "../../core/constant/common.constant";

export default function HomePage() {
  const firstSectionRef = useRef<any>(null);
  const secondSectionRef = useRef<any>(null);
  const thirdSectionRef = useRef<any>(null);
  const fourthSectionRef = useRef<any>(null);
  const [firstVisible, setFirstVisible] = useState<any>();
  const [secondVisible, setSecondVisible] = useState<any>();
  const [thirdVisible, setThirdVisible] = useState<any>();
  const [fourthVisible, setFourthVisible] = useState<any>();

  const [inventionNm, setInventionNm] = useState<any>("");
  const [inventionSggt, setInventionSggt] = useState<any>([]);
  const [inventionSlt, setInventionSlt] = useState<any>({});

  const [debounceValue, setDebounceValue] = useState<any>();
  const [loadingSggt, setLoadingSggt] = useState<any>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Kiểm tra nếu phần tử xuất hiện trong viewport
        if (entry.isIntersecting === true) setFirstVisible(entry.isIntersecting);
      },
      {
        root: null, // null là viewport hiện tại
        threshold: 1, // Kích hoạt khi ít nhất 50% phần tử xuất hiện
      }
    );

    if (firstSectionRef.current) {
      observer.observe(firstSectionRef.current);
    }

    return () => {
      if (firstSectionRef.current) {
        observer.unobserve(firstSectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Kiểm tra nếu phần tử xuất hiện trong viewport
        if (entry.isIntersecting === true) setSecondVisible(entry.isIntersecting);
      },
      {
        root: null, // null là viewport hiện tại
        threshold: 1, // Kích hoạt khi ít nhất 50% phần tử xuất hiện
      }
    );

    if (secondSectionRef.current) {
      observer.observe(secondSectionRef.current);
    }

    return () => {
      if (secondSectionRef.current) {
        observer.unobserve(secondSectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Kiểm tra nếu phần tử xuất hiện trong viewport
        if (entry.isIntersecting === true) setThirdVisible(entry.isIntersecting);
      },
      {
        root: null, // null là viewport hiện tại
        threshold: 1, // Kích hoạt khi ít nhất 50% phần tử xuất hiện
      }
    );

    if (thirdSectionRef.current) {
      observer.observe(thirdSectionRef.current);
    }

    return () => {
      if (thirdSectionRef.current) {
        observer.unobserve(thirdSectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Kiểm tra nếu phần tử xuất hiện trong viewport
        if (entry.isIntersecting === true) setFourthVisible(entry.isIntersecting);
      },
      {
        root: null, // null là viewport hiện tại
        threshold: 1, // Kích hoạt khi ít nhất 50% phần tử xuất hiện
      }
    );

    if (fourthSectionRef.current) {
      observer.observe(fourthSectionRef.current);
    }

    return () => {
      if (fourthSectionRef.current) {
        observer.unobserve(fourthSectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (debounceValue && debounceValue.trim().length > 0) {
      getListInventionSggt();
    } else {
      setInventionSggt([]);
      setLoadingSggt(false);
    }
  }, [debounceValue]);

  const getListInventionSggt = () => {
    InventionService.getInstance()
      .searchInventionApproved({ inventionNm: inventionNm, approveStatus: CommonConstant.INVENTION_APPROVE_STATUS_APPROVED })
      .then((res: any) => {
        setInventionSggt(res.data.responseData.result);
        setLoadingSggt(false);
      });
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(inventionNm);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [inventionNm]);

  const handleChangeInventionNm = (event: any) => {
    setLoadingSggt(true);
    setInventionNm(event.target.value);
  };

  const handleSelectInvention = (data: any) => {
    navigate("/tim-kiem?ten-sang-kien=" + data.inventionNm);
  };

  const handleEnter = (event: any) => {
    if (event.keyCode === 13) {
      if (inventionNm.trim() === "") {
        navigate("/tim-kiem");
      } else {
        navigate("/tim-kiem?ten-sang-kien=" + inventionNm);
      }
    }
  };

  return (
    <>
      <div className="section-spacing pb-5">
        <div className="container pt-3">
          <div className="row g-4">
            <div className="col-12 col-xxl-10 offset-xxl-1">
              <h1 className="d-none d-lg-block display-6 display-lg font-family-poppins fw-semi-bold uppercase">
                <span className="text-gradient-5 fw-bold display-3">Cổng thông tin</span>
                <span className="d-block fw-bold  text-end fs-2">Dữ Liệu Thị Trường Khoa Học - Công Nghệ Quốc Gia</span>
              </h1>
              <h1 className="d-lg-none font-family-poppins uppercase">
                <span className="text-gradient-5 display-5 fw-semi-bold">Cổng thông tin</span>
                <span className="d-block fs-6 fw-bold text-lg-end ">Dữ Liệu Thị Trường Khoa Học - Công Nghệ Quốc Gia</span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing pb-6">
        <div className="container">
          <h3 className=" fw-medium text-center pb-3">
            <span className="text-highlight-gradient-5">Thông tin Khoa Học - Công Nghệ</span> mà bạn đang quan tâm?
          </h3>
          <div className="row">
            <div className="contact-form">
              <div className="form-border-radius center" id="contactform">
                <div className="row gx-3 gy-0 justify-content-center">
                  <div className="col-12 col-md-6 justify-content-center">
                    <AppAutocomplete
                      value={inventionNm}
                      handleEnter={handleEnter}
                      loading={loadingSggt}
                      bindLabel={"inventionNm"}
                      suggestion={inventionSggt}
                      optionSlt={inventionSlt}
                      placeholder={"Tìm kiếm ..."}
                      handleChangeInput={handleChangeInventionNm}
                      handleSelectOption={handleSelectInvention}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-3 px-md-4 px-xxl-5">
        <div className="position-relative img-mask-1 " style={{ backgroundColor: "#eaf6f6" }}>
          <div className="z-index-1 d-flex flex-column flex-md-row p-5">
            <div className="d-flex flex-column h-100 align-items-start justify-content-center w-100 w-md-50 px-4 px-md-0 align-self-center" style={{ paddingLeft: "10rem" }}>
              <div className="text-start text-md-left align-self-center">
                <h2>
                  Đăng Ký Thông Tin Sáng Chế <br />
                  Khoa Học Công Nghệ Của Bạn
                </h2>
                <p>
                  Để được <strong>định giá</strong>, <strong>bảo hộ</strong> và <strong>chuyển nhượng</strong> một cách an toàn,
                  <br /> linh hoạt với những quy định hiện hành mới nhất và vô cùng nhanh chóng.
                </p>
              </div>
              <div className="text-center text-md-left mt-3 align-self-center">
                <Link className="button button-rounded button-gradient-5" to="/dang-ky-sang-che">
                  Đăng Ký Ngay
                </Link>
              </div>
            </div>
            <div className="w-100 w-md-50 h-100 mt-4 mt-md-0 text-center">
              <Lottie loop animationData={animationData} play style={{ height: "100%", maxHeight: "400px", display: "inline-block" }} />
            </div>
          </div>
          {/*<div className="section-spacing-xl" style={{backgroundColor: "#eaf6f6"}}>*/}
          {/*  <div className="py-lg-4 py-xl-5"></div>*/}
          {/*</div>*/}
        </div>
      </div>

      <div className="section-spacing">
        <div className="container">
          <div className="bg-gradient-5 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur">
            <div className="row g-4">
              <div className="col-12 col-lg-3">
                <div className="h2 fw-normal mb-3">
                  <span className="text-gradient-5">01.</span>
                </div>
                <h4 className="text-dark">Đăng ký</h4>
                <p className="text-dark-70">Đăng ký thông tin chi tiết về sáng chế để đảm bảo tính hợp pháp.</p>
              </div>
              <div className="col-12 col-lg-3">
                <div className="h2 fw-normal mb-3">
                  <span className="text-gradient-1">02.</span>
                </div>
                <h4 className="text-dark">Định giá theo quy trình</h4>
                <p className="text-dark-70">Xác định giá trị sáng chế theo quy trình chuẩn, được cập nhật liên tục theo, thị trường và chính sách của nhà nước.</p>
              </div>
              <div className="col-12 col-lg-3">
                <div className="h2 fw-normal mb-3">
                  <span className="text-gradient-5">03.</span>
                </div>
                <h4 className="text-dark">Bảo hộ quyền sở hữu</h4>
                <p className="text-dark-70">Phát hiện vi phạm, đảm bảo quyền sở hữu trí tuệ của sáng chế thông qua đăng ký và bảo vệ pháp lý.</p>
              </div>
              <div className="col-12 col-lg-3">
                <div className="h2 fw-normal mb-3">
                  <span className="text-gradient-1">04.</span>
                </div>
                <h4 className="text-dark">Mua bán, chuyển nhượng</h4>
                <p className="text-dark-70">Chuyển nhượng quyền sở hữu hoặc quyền sử dụng sáng chế giữa các bên.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-12 col-lg-4">
              <div className="circle-text">
                <svg viewBox="0 0 200 200" className="circle-svg rotating">
                  <path id="textPath" d="M 85,0 A 85,85 0 0 1 -85,0 A 85,85 0 0 1 85,0" transform="translate(100,100)" fill="none" stroke="rgba(28, 30, 32, 0.1)" strokeWidth="10"></path>
                  <text textAnchor="start">
                    <textPath xlinkHref="#textPath" textLength="260%">
                      Hệ Thống Quản Lý Dữ Liệu Thị Trường Khoa Học - Công Nghệ Quốc Gia •
                    </textPath>
                  </text>
                </svg>
                <div className="circle-text-inner icon-4xl text-gradient-5">
                  <i className="ti-crown"></i>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="d-flex align-items-center mb-4">
                <ul className="avatar-group">
                  <li>
                    <img src={require("../../../assets/images/testimonial-4.jpg")} alt="" data-rjs="2" />
                  </li>
                  <li>
                    <img src={require("../../../assets/images/testimonial-5.jpg")} alt="" data-rjs="2" />
                  </li>
                  <li>
                    <img src={require("../../../assets/images/testimonial-7.jpg")} alt="" data-rjs="2" />
                  </li>
                </ul>
                <div className="d-inline-block ps-3">
                  <p>
                    Trên 4,000 cá nhân, tập thể, tổ chức, doanh nghiệp <br className="d-md-block" />
                    đã đăng ký.
                  </p>
                </div>
              </div>
              <h2 className="fs-4 fw-normal line-height-140">
                Với <span className="fw-bold">Hệ thống Quản Lý Dữ Liệu Thị Trường Khoa Học - Công Nghệ Quốc Gia,</span>
                <br />
                việc bảo hộ bản quyền, định giá, mua bán trao đổi trở nên dễ dàng, minh bạch và nhanh chóng hơn bao giờ hết.
              </h2>
              <a className="button button-lg button-dark button-rounded button-hover-slide mt-4" href="/ve-chung-toi">
                <span data-text="Xem thêm">Xem thêm</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row g-4 mb-4" ref={firstSectionRef}>
          <div className="col-12 col-lg-4 bg-white border-radius-1">
            <Animated animationIn="slideInUp" isVisible={firstVisible ? true : false}>
              {firstVisible && (
                <div className="bg-gradient-1 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-router text-gradient-1"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học tự nhiên</h5>
                  </div>
                </div>
              )}
            </Animated>
          </div>
          <div className="col-12 col-lg-4 bg-white border-radius-1">
            <Animated animationIn="slideInUp" isVisible={firstVisible ? true : false}>
              {firstVisible && (
                <div className="bg-gradient-2 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-lightning-charge text-gradient-2"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học kỹ thuật và công nghệ</h5>
                  </div>
                </div>
              )}
            </Animated>
          </div>
          <div className="col-12 col-lg-4 bg-white border-radius-1">
            <Animated animationIn="slideInUp" isVisible={firstVisible ? true : false}>
              {secondVisible && (
                <div className="bg-gradient-5 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-prescription2 text-gradient-5"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học y, dược</h5>
                  </div>
                </div>
              )}
            </Animated>
          </div>
        </div>
        <div className="row g-4 mb-4 bg-white border-radius-1" ref={secondSectionRef}>
          <div className="col-12 col-lg-4">
            <Animated animationIn="slideInUp" isVisible={firstVisible ? true : false}>
              {secondVisible && (
                <div className="bg-gradient-5 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-cpu text-gradient-5"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học nông nghiệp</h5>
                  </div>
                </div>
              )}
            </Animated>
          </div>
          <div className="col-12 col-lg-4 bg-white border-radius-1">
            <Animated animationIn="slideInUp" isVisible={firstVisible ? true : false}>
              {firstVisible && (
                <div className="bg-gradient-1 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-feather text-gradient-1"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học xã hội</h5>
                  </div>
                </div>
              )}
            </Animated>
          </div>
          <div className="col-12 col-lg-4 bg-white border-radius-1">
            <Animated animationIn="slideInUp" isVisible={firstVisible ? true : false}>
              {firstVisible && (
                <div className="bg-gradient-2 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-truck text-gradient-2"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học nhân văn</h5>
                  </div>
                </div>
              )}
            </Animated>
          </div>
        </div>
      </div>

      <div className="section-spacing-sm pb-0">
        <div className="container">
          <Swiper
            modules={[Autoplay]}
            spaceBetween={50} // Khoảng cách giữa các slide
            slidesPerView={6} // Số slide hiển thị cùng lúc
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}>
            <SwiperSlide style={{ minWidth: "150px" }}>
              <img src={require("../../../assets/images/client-1-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide style={{ minWidth: "150px" }}>
              <img src={require("../../../assets/images/client-2-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide style={{ minWidth: "150px" }}>
              <img src={require("../../../assets/images/client-3-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide style={{ minWidth: "150px" }}>
              <img src={require("../../../assets/images/client-4-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide style={{ minWidth: "150px" }}>
              <img src={require("../../../assets/images/client-5-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide style={{ minWidth: "150px" }}>
              <img src={require("../../../assets/images/client-6-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide style={{ minWidth: "150px" }}>
              <img src={require("../../../assets/images/client-1-dark.png")} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className="section-spacing">
        <div className="container">
          <div className="row align-items-center custom-row">
            <div className="col-12 col-lg-6 custom-col">
              <div className="row g-3">
                <div className="col-6 text-end" data-bottom-top="transform: translateY(10px)" data-top-bottom="transform: translateY(-36px)">
                  <img className="border-radius-1" src={require("../../../assets/images/business-about-2.jpg")} alt="" />
                  <div className="d-sm-flex align-items-center mt-1">
                    <p>
                      Vi phạm bản quyền
                      <br /> mỗi ngày
                    </p>
                    <div className="h2 display-3 fw-normal ps-3">
                      <span className="text-gradient-1">99+</span>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-5" data-bottom-top="transform: translateY(-10px)" data-top-bottom="transform: translateY(40px)">
                  <img className="border-radius-1" src={require("../../../assets/images/corporate-about-2.jpg")} alt="" />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 custom-col">
              <h2 className="display-7 fw-normal">Đăng ký thông tin sáng chế, giải pháp KTCN là điều thiết yếu</h2>
              <div ref={thirdSectionRef}>
                <Animated animationIn="slideInRight" isVisible={thirdVisible ? true : false}>
                  {thirdVisible && (
                    <>
                      <div className="d-flex align-items-center mt-4">
                        <div className="icon-3xl text-gradient-5 pe-4">
                          <i className="ti-check-box"></i>
                        </div>
                        <p>Quản lý và định giá các sáng chế giải pháp theo chính sách và luật pháp hiện hành.</p>
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <div className="icon-3xl text-gradient-5 pe-4">
                          <i className="ti-check-box"></i>
                        </div>
                        <p>Bảo hộ quyền sở hữu trí tuệ. Nhanh chóng phát hiện và xử lý tranh chấp hiệu quả.</p>
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <div className="icon-3xl text-gradient-5 pe-4">
                          <i className="ti-check-box"></i>
                        </div>
                        <p>Mua bán, chuyển nhượng các sáng chế, giải pháp một cách nhanh chóng và minh bạch .</p>
                      </div>
                    </>
                  )}
                </Animated>
              </div>
              <div ref={fourthSectionRef}>
                <Animated animationIn="fadeIn" isVisible={fourthVisible ? true : false}>
                  {fourthVisible && (
                    <a className="button button-lg button-dark button-rounded button-hover-slide mt-4" href="#">
                      <span data-text="Đọc thêm">Đọc thêm</span>
                    </a>
                  )}
                </Animated>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Marquee speed={100}>
        <div className="marquee-container">
          <div className="marquee">
            <h2 className="display-1 font-family-poppins fw-semi-bold uppercase stroke-text opacity-30 mb-0">Bằng sáng chế</h2>
            <h2 className="display-1 font-family-poppins fw-semi-bold uppercase stroke-text opacity-30 mb-0">Giải pháp</h2>
            <h2 className="display-1 font-family-poppins fw-semi-bold uppercase stroke-text opacity-30 mb-0">Khoa học - công nghệ</h2>
            <h2 className="display-1 font-family-poppins fw-semi-bold uppercase stroke-text opacity-30 mb-0">Truy xuất dữ liệu</h2>
          </div>
        </div>
      </Marquee>

      <div className="section-spacing pt-0">
        <div className="container">
          <div className="row g-4 g-xl-5 align-items-center">
            <div className="col-12 col-lg-6">
              <h2 className="display-5 fw-normal">Vinh danh</h2>
              {/*<ul className="list-inline mt-3">*/}
              {/*<li>*/}
              {/*    <a className="button-circle button-circle-dark button-circle-hover-slide blog-slider-3-prev"*/}
              {/*       href="#">*/}
              {/*      <i className="bi bi-arrow-left"></i>*/}
              {/*      <i className="bi bi-arrow-left"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <a className="button-circle button-circle-dark button-circle-hover-slide blog-slider-3-next"*/}
              {/*       href="#">*/}
              {/*      <i className="bi bi-arrow-right"></i>*/}
              {/*      <i className="bi bi-arrow-right"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
            {/*<div className="col-12 col-lg-6">*/}
            {/*  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore*/}
            {/*    et dolore magna aliqua</p>*/}
            {/*  <div className="mt-3">*/}
            {/*    <a className="button-fancy-gradient-1" href="#">*/}
            {/*      View More Posts*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="mt-4 mt-lg-5">
            <Swiper
              modules={[Pagination]}
              spaceBetween={40} // Khoảng cách giữa các slide
              // slidesPerView={3} // Số slide hiển thị cùng lúc
              breakpoints={{
                0: {
                  // width: 576,
                  slidesPerView: 1,
                },
                576: {
                  // width: 576,
                  slidesPerView: 2,
                },
                768: {
                  // width: 768,
                  slidesPerView: 3,
                },
              }}>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img src={require("../../../assets/images/blog-slide-1.jpg")} alt="" />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Work</li>
                    </ul>
                    <h4 className="mb-0">
                      <a className="link-hover-line" href="blog/blog-single.html">
                        Best work environment
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img src={require("../../../assets/images/blog-slide-2.jpg")} alt="" />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Lifestyle</li>
                    </ul>
                    <h4 className="mb-0">
                      <a className="link-hover-line" href="blog/blog-single.html">
                        Keeping things simple
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img src={require("../../../assets/images/blog-slide-3.jpg")} alt="" />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Tips</li>
                    </ul>
                    <h4 className="mb-0">
                      <a className="link-hover-line" href="blog/blog-single.html">
                        Tips for a healthy lifestyle
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img src={require("../../../assets/images/blog-slide-4.jpg")} alt="" />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Lifestyle</li>
                    </ul>
                    <h4 className="mb-0">
                      <a className="link-hover-line" href="blog/blog-single.html">
                        Work-Life Balance
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="cursors">
        <div className="cursor-inner"></div>
      </div>

      {/*<div className="bg-lines-3 bg-lines-animated">*/}
      {/*  <div className="line-1"></div>*/}
      {/*  <div className="line-2"></div>*/}
      {/*  <div className="line-3"></div>*/}
      {/*</div>*/}

      <div className="scrolltotop icon-lg">
        <i className="bi bi-arrow-up-short"></i>
      </div>
    </>
  );
}
