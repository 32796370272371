import { useEffect, useState } from "react";
import { useAppSelector } from "../../libs/hooks";
import InventionService from "../../core/services/InventionService";
import moment from "moment";

export default function UserInfo() {
  const [statisticsInvention, setStatisticsInvention] = useState<any>();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Lắng nghe sự kiện resize
    window.addEventListener("resize", handleResize);

    // Dọn dẹp sự kiện khi component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (userInfo.userUid) {
      InventionService.getInstance()
        .statisticsInvention({ userUid: userInfo.userUid })
        .then((res: any) => {
          setStatisticsInvention(res.data.responseData);
        });
    }
  }, [userInfo]);

  return (
    <>
      <div className="container pt-3">
        <div className={"row g-4 gap-4" + (windowWidth > 1000 ? " flex-nowrap" : " flex-wrap")}>
          <div className="col-lg-4">
            <div className="bg-gradient-5 bg-opacity-10 p-4  backdrop-filter-blur border-radius-1">
              <div className="d-flex justify-content-center">
                <i className="bi bi-person-circle fs-1 me-2 text-dark text-dark"></i>
              </div>
              <div className="d-flex justify-content-center py-3">
                <h5 className="nav-link text-dark">{userInfo.fullName}</h5>
              </div>
              <div className="d-flex text-dark py-3 border-1 border-top border-dark-subtle">
                <div className="col-lg-4 p-1">
                  <div className="fs-3 text-center text-success">{statisticsInvention?.inventionApproved}</div>
                  <div className="fs-6 text-center">sáng chế đã được duyệt</div>
                </div>
                <div className="col-lg-4 p-1 border-1 border-start border-end border-dark-subtle">
                  <div className="fs-3 text-center text-warning">{statisticsInvention?.inventionPending}</div>
                  <div className="fs-6 text-center">sáng chế đang chờ duyệt</div>
                </div>
                <div className="col-lg-4 p-1">
                  <div className="fs-3 text-center text-danger">{statisticsInvention?.inventionRejected}</div>
                  <div className="fs-6 text-center">sáng chế đã bị từ chối</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="bg-gradient-5 bg-opacity-10 p-4 backdrop-filter-blur border-radius-1">
              <div className="d-flex mt-3 border-1 border-bottom border-dark-subtle">
                <h5 className="text-dark fw-semibold">Thông tin cá nhân</h5>
              </div>
              <div>
                <div className="row py-3 text-dark">
                  <div className="fw-medium col-lg-2">Họ và tên:</div>
                  <div className="col-lg-10">{userInfo.fullName}</div>
                </div>
                <div className="row py-3 text-dark">
                  <div className="fw-medium col-lg-2">Ngày sinh:</div>
                  <div className="col-lg-10">{moment(userInfo.dob).format("DD/MM/YYYY")}</div>
                </div>
                <div className="row py-3 text-dark">
                  <div className="fw-medium col-lg-2">Số điện thoại:</div>
                  <div className="col-lg-10">{userInfo.cellPhone}</div>
                </div>
                <div className="row py-3 text-dark">
                  <div className="fw-medium col-lg-2">Giới tính:</div>
                  <div className="col-lg-10">{userInfo.gender ? "Nam" : "Nữ"}</div>
                </div>
                <div className="row py-3 text-dark">
                  <div className="fw-medium col-lg-2">Email:</div>
                  <div className="col-lg-10">{userInfo.email}</div>
                </div>
                <div className="row py-3 text-dark">
                  <div className="fw-medium col-lg-2">Địa chỉ:</div>
                  <div className="col-lg-10">{userInfo.address}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
